import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import { breakpoints } from '../style'

import Header from './header'
import './layout.css'
import bgimg from '../images/hexes.svg'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div css={bg}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div css={page}>{children}</div>
        <footer css={footer}>
          © {new Date().getFullYear()} Cooperative Shenanigans
        </footer>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const bg = css`
  background-image: url(${bgimg});
`

const page = css`
  margin: 0 auto;
  max-width: 960px;
  padding: 2rem 2rem;
  @media only screen and (max-width: ${breakpoints.max}px) {
    margin: 0;
    padding: 2rem 0;
  }
`

const footer = css`
  margin-top: 10rem;
  padding: 2rem;
`

export default Layout
