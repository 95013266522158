import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/ContactForm'

const ContactUs = () => (
  <Layout>
    <div css={content}>
      <p css={section}>
        Interested in helping us play-test our game prototyping tools? Are you
        looking for consultation or other services? Please fill out the form
        below and we'll get back to you as soon as we can.
      </p>

      <ContactForm />
    </div>
    <SEO title="Contact Cooperative Shenanigans!" />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

const content = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  --hex-width: 400px;
`

const section = css`
  width: 90%;
  color: #efefec;
  /* background-color: rgba(111, 118, 173, .7); */
  background-color: rgba(20, 32, 80, 0.85);
  border-radius: 5px;
  padding: 2rem;
  margin-bottom: 2.5rem;

  a {
    text-decoration: underline;
  }
`

export default ContactUs
