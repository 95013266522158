import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { css } from '@emotion/core'

const Header = ({ siteTitle }) => (
  <header css={header}>
    <div css={headerContent}>
      <h1>
        <Link to="/">{siteTitle}</Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const header = css`
  /* background-color: rgb(127, 47, 153); */
  background-color: #4f5790;
  margin-bottom: 1.45rem;
`

const headerContent = css`
  margin: 0 auto;
  max-width: 960;
  padding: 3.45rem 2.0875rem;

  h1 {
    margin: 0;
  }

  a {
    color: white;
  }
`
export default Header
