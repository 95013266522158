import React from 'react'
// import { Link } from 'gatsby'
import { withFormik, Form, Field } from 'formik'
import { css } from '@emotion/core'

import * as Yup from 'yup'

const ContactForm = () => {
  return <FormikApp email="" />
}

const myForm = ({ values, errors, touched, isSubmitting }) => {
  return (
    <Form css={section}>
      <p>
        <Field type="email" name="email" placeholder="Email" /> Email
        {touched.email && errors.email && <p>{errors.email}</p>}
      </p>

      {/* <p>
        <Field
          type="password" name="password"
          placeholder="Password"
        /> Password<br/>
        { touched.password && errors.password && <p>{errors.password}</p>}
      </p> */}

      <p>
        <label htmlFor="consulting">
          <Field
            type="checkbox"
            name="consulting"
            checked={values.consulting}
          />{' '}
          Tell me about your consulting services!{' '}
        </label>
      </p>

      <p>
        <label htmlFor="playtest">
          <Field type="checkbox" name="playtest" checked={values.playtest} />{' '}
          Tell me about your playtest tools!{' '}
        </label>
      </p>

      <p>
        <label htmlFor="newsletter">
          <Field
            type="checkbox"
            name="newsletter"
            checked={values.newsletter}
          />{' '}
          Tell me about Cooperative Shenanigans news! Add me to your mailing
          list.{' '}
        </label>
      </p>

      {/* <Field component="select" name="plan">
        <option value="free">Free</option>
        <option value="premium">Premium</option>
      </Field> */}
      <button disabled={isSubmitting} type="submit">
        Submit
      </button>
    </Form>
  )
}

const FormikApp = withFormik({
  mapPropsToValues({ email, password, newsletter, plan }) {
    return {
      email: email || 'enter your email',
      password: password || '',
      newsletter: newsletter || false,
      plan: plan || 'free',
    }
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
    password: Yup.string()
      .min(9, 'Password must be at least 9 characters long')
      .required('You must enter a password'),
  }),
  // handleSubmit(values) {
  //   console.log(values)
  // },
  handleSubmit(values, { resetForm, setErrors, setSubmitting }) {
    setTimeout(() => {
      if (values.email === 'maka@shenans.co') {
        setErrors({ email: 'Email is already in our system!' })
      } else {
        resetForm()
      }
      setSubmitting(false)
    }, 2000)
  },
})(myForm)

const section = css`
  width: 90%;
  color: #efefec;
  /* background-color: rgba(111, 118, 173, .7); */
  background-color: rgba(20, 32, 80, 0.85);
  border-radius: 5px;
  padding: 2rem;
  margin-bottom: 2.5rem;

  a {
    text-decoration: underline;
  }

  p {
    margin: 0.5rem 0rem;
  }
`

export default ContactForm
